/** *******************************************************
 * There is the store to store constants that relate to
 * actions which will be sent to the reducers
 ******************************************************** */

// INIT MEMBERS
export const FETCH_MEMBERS_START = 'FETCH_MEMBERS_START';
export const RECEIVE_MEMBERS = 'RECIVES_MEMBERS';
export const FETCH_MEMBERS_ERROR = 'FETCH_MEMBERS_ERROR';

// FIND USERS
export const FIND_USERS_START = 'FIND_USERS_START';
export const FOUND_USERS = 'FOUND_USERS';
export const FIND_USERS_ERROR = 'FIND_USERS_ERROR';
export const REMOVE_FOUND_USER = 'REMOVE_FOUND_USER';

// ASSIGN NEW MEMBERS
export const ADD_NEW_MEMBER = 'ADD_NEW_MEMBER';
export const ADD_NEW_MEMBER_ERROR = 'ADD_NEW_MEMBER_ERROR';

// DELETE MEMBER
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';
