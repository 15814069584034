// INIT ROLES
export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const RECEIVE_ROLES = 'RECEIVE_ROLES';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';

// ASSIGN NEW ROLES
export const ADD_NEW_ROLE = 'ADD_NEW_ROLE';
export const ADD_NEW_ROLE_ERROR = 'ADD_NEW_ROLE_ERROR';

// UPDATE ROLE
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';
