// INIT PRESETS
export const RECEIVE_PRESETS = 'RECEIVE_PRESETS';

// ADD NEW PRESET
export const ADD_NEW_PRESET = 'ADD_NEW_PRESET';

// UPDATE PRESET
export const UPDATE_PRESET = 'UPDATE_PRESET';

// DELETE PRESET
export const DELETE_PRESET = 'DELETE_PRESET';
