/** *******************************************************
 * There is the store to store constants that relate to
 * actions which will be sent to the reducers
 ******************************************************** */

// INIT TASKS
export const FETCH_TASKS_START = 'FETCH_TASKS_START';
export const RECEIVE_TASKS = 'RECEIVE_TASKS';
export const FETCH_TASKS_ERROR = 'FETCH_TASKS_ERROR';

// ADD NEW TASKS
export const ADD_NEW_TASK = 'ADD_NEW_TASK';
export const ADD_NEW_TASK_ERROR = 'ADD_NEW_TASK_ERROR';

// UPDATE TASKS
export const UPDATE_TASK = 'UPDATE_TASK';

// DELETE TASK
export const DELETE_TASK = 'DELETE_TASK';

// SWAP TASKS
export const SWAP_TASKS = 'SWAP_TASKS';

// UPDATE NUMS
export const UPDATE_NUMS = 'UPDATE_NUMS';

// COPY TASK
export const COPY_TASK = 'COPY_TASK';

// EMPTY TASK ITEMS
export const EMPTY_TASK_ITEMS = 'EMPTY_TASK_ITEMS';
