export const SET_MATERIALS = 'SET_MATERIALS';

export const RESET_UPDATE_MATERIAL = 'RESET_UPDATE_MATERIAL';
export const POST_UPDATE_MATERIAL_START = 'POST_UPDATE_MATERIAL_START';
export const POST_UPDATE_MATERIAL_END = 'POST_UPDATE_MATERIAL_END';
export const POST_UPDATE_MATERIAL_ERROR = 'POST_UPDATE_MATERIAL_ERROR';

export const RESET_UPDATE_MATERIAL_BULK = 'RESET_UPDATE_MATERIAL_BULK';
export const POST_UPDATE_MATERIAL_START_BULK = 'POST_UPDATE_MATERIAL_START_BULK';
export const POST_UPDATE_MATERIAL_END_BULK = 'POST_UPDATE_MATERIAL_END_BULK';
export const POST_UPDATE_MATERIAL_ERROR_BULK = 'POST_UPDATE_MATERIAL_ERROR_BULK';

export default SET_MATERIALS;
