export const FETCH_USER_PROFILES_START = 'FETCH_USER_PROFILES_START';
export const RECEIVE_ALL_USER_PROFILES = 'RECIVES_USER_PROFILES';
export const FETCH_USER_PROFILES_ERROR = 'FETCH_USER_PROFILES_ERROR';
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_DELETE = 'USER_PROFILE_DELETE';
export const FETCH_USER_PROFILE_BASIC_INFO = 'FETCH_USER_PROFILE_BASIC_INFO';
export const RECEIVE_USER_PROFILE_BASIC_INFO = 'RECEIVE_USER_PROFILE_BASIC_INFO';
export const FETCH_USER_PROFILE_BASIC_INFO_ERROR = 'FETCH_USER_PROFILE_BASIC_INFO_ERROR';

export const ENABLE_USER_PROFILE_EDIT = 'ENABLE_USER_PROFILE_EDIT';
export const DISABLE_USER_PROFILE_EDIT = 'DISABLE_USER_PROFILE_EDIT';
export const CHANGE_USER_PROFILE_PAGE = 'CHANGE_USER_PROFILE_PAGE';

export const START_USER_INFO_UPDATE = 'START_USER_INFO_UPDATE';
export const FINISH_USER_INFO_UPDATE = 'FINISH_USER_INFO_UPDATE';
export const ERROR_USER_INFO_UPDATE = 'ERROR_USER_INFO_UPDATE';
